import { KeyActions, useKeyContext } from "@/hooks/context/scan";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAccessScan,
  selectActiveScan,
  selectOrderId,
  setAccessScan,
  setActiveScan,
  setOrderId,
} from "./state/slice/data";
import { unwrapErrorMessage, useGetOrderByIdQuery } from "./state/services/api";
import ScanRow from "@/ui/components/scan-row";
import GoButton from "@/ui/components/go-button";
import { stepForward } from "./state/slice/page";

export function SafetyCheckScanOrder() {
  const [active, setActive] = useState<"order" | "access" | "next">("order");
  const orderIdScan = useSelector(selectOrderId);
  const accessScan = useSelector(selectAccessScan);
  const activeScan = useSelector(selectActiveScan);
  const { data, error, isLoading } = useGetOrderByIdQuery(orderIdScan, {
    skip: orderIdScan === "",
  });
  const subscribe = useKeyContext();
  const dispatch = useDispatch();

  const orderError = {
    error: error && "status" in error ? true : false,
    msg: unwrapErrorMessage(error),
  };

  const orderAccessCode = data && data.access_code;
  // const orderAccessCode = "test";
  const accessError =
    accessScan && orderAccessCode?.toLowerCase() !== accessScan.toLowerCase();

  useEffect(() => {
    if (active === "order") {
      if (orderIdScan !== "") {
        setActive("access");
      }

      const unsubscribe = subscribe((event) => {
        switch (event.type) {
          case KeyActions.key:
            return dispatch(setActiveScan(event.key));
          case KeyActions.enter:
            dispatch(setOrderId());
            return setActive("access");
        }
      });

      return () => {
        unsubscribe();
      };
    }
  }, [active]);

  useEffect(() => {
    if (active === "access") {
      if (accessScan !== "") {
        setActive("next");
      }

      const unsubscribe = subscribe((event) => {
        switch (event.type) {
          case KeyActions.key:
            if (!data) return;

            return dispatch(setActiveScan(event.key));
          case KeyActions.enter:
            if (!data) return;

            dispatch(setAccessScan());
            return setActive("next");
        }
      });

      return () => {
        unsubscribe();
      };
    }
  }, [active, data]);

  return (
    <>
      <section className="scan-order__wrapper">
        <div className="scan-order__header">
          <div>Order Number</div>
          <div>Access Code</div>
        </div>
        <ScanRow
          primary={{
            text: active === "order" ? activeScan : orderIdScan,
            err: orderError.error,
            errMsg: orderError.msg,
          }}
          secondary={{
            text: active === "access" ? activeScan : accessScan,
            err: accessError,
            errMsg: accessError ? "Access code does not match" : "",
          }}
          onClick={() => {}}
          checkActive={active === "next" && !accessError}
        />
      </section>
      {active === "next" && !accessError && !isLoading && data && (
        <section className="safetycheck__next-btn">
          <GoButton
            onClick={() => {
              dispatch(stepForward());
            }}
          />
        </section>
      )}
    </>
  );
}
