import { useLogin } from "@/hooks/context/login";
import BaseButton from "@/ui/base/button";
import CountdownTimer from "@/ui/components/countdown-timer";
import { useDispatch } from "react-redux";
import { reset } from "./state/actions/reset";
import { api } from "./state/services/api";
import useTimeout from "@/hooks/use-timeout";

export function SafetyCheckReview() {
  const [{ username }, loginDispatch] = useLogin();
  const dispatch = useDispatch();

  const timerStart = 20;
  const timerRunning = true;

  const handleLogout = () => {
    dispatch(reset());
    dispatch(api.util.resetApiState());
    loginDispatch({ type: "LOGOUT" });
  };

  useTimeout(handleLogout, timerRunning, timerStart * 1000);

  return (
    <section className="safetyscans-review-order__wrapper">
      <h1 className="safetyscans-review-order__header">
        {username}, your work has been logged.
      </h1>
      <h2 className="go-again__header">
        Would you like to safety scan another order?
      </h2>
      <CountdownTimer
        isRunning={timerRunning}
        startTime={timerStart}
        className="go-again__timer"
      />
      <section className="go-again__buttons-wrapper">
        <BaseButton
          className="go-again__buttons again"
          children="GO AGAIN"
          onClick={() => {
            dispatch(reset());
            dispatch(api.util.resetApiState());
          }}
        />
        <BaseButton
          className="go-again__buttons done"
          children="LOG OUT"
          onClick={handleLogout}
        />
      </section>
    </section>
  );
}
