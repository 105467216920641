import {
  createAsyncThunk,
  createSlice,
  type PayloadAction,
} from "@reduxjs/toolkit";
import {
  AppThunk,
  createAppAsyncThunk,
  type AppDispatch,
  type RootState,
} from "../store";
import { api } from "../services/api";
import type { Api } from "@/types/api";
import { picklist, is } from "valibot";
import { startAppListening } from "../listener";
import { isBarcodeMatchingOrderId } from "@/hooks";
import { reset } from "../actions/reset";
import { createAlert } from "../services/updates";

export const departments = ["Tapes", "Film", "Photos", "Audio"] as const;
export const deptSchema = picklist(departments);

export interface DataState {
  department: (typeof departments)[number] | null;
  orderIdScan: string;
  accessScan: string;
  activeScan: string;
  analogItems: (Api.ISafetyScansItem & { scanned: boolean })[];
  orderData: Api.IProductionOrder | null;
  itemError: {
    status: boolean;
    msg: string;
    recoverable: boolean;
  };
}

const setInitialState = (): DataState => {
  const dept = localStorage.getItem("SAFEY_CHECK_DEPT");

  const department = is(deptSchema, dept) ? dept : null;

  return {
    department,
    orderIdScan: "",
    accessScan: "",
    activeScan: "",
    analogItems: [],
    orderData: null,
    itemError: {
      status: false,
      msg: "",
      recoverable: false,
    },
  };
};

export const dataSlice = createSlice({
  name: "data",
  initialState: setInitialState(),
  reducers: {
    setOrderId: (state) => {
      state.orderIdScan = state.activeScan;
      state.activeScan = "";
    },
    setAccessScan: (state) => {
      state.accessScan = state.activeScan;
      state.activeScan = "";
    },
    setActiveScan: (state, action: PayloadAction<DataState["activeScan"]>) => {
      state.activeScan = state.activeScan + action.payload;
    },
    clearActiveScan: (state) => {
      state.activeScan = "";
    },
    setDepartment: (state, action: PayloadAction<DataState["department"]>) => {
      state.department = action.payload;
    },
    resetDepartment: (state) => {
      state.department = null;
    },
    scanItem: (state, action: PayloadAction<{ barcode: string }>) => {
      const scannedItem = state.analogItems.find(
        (item) => item.item_barcode === action.payload.barcode,
      );

      if (!scannedItem) {
        state.itemError = {
          status: true,
          msg: action.payload.barcode,
          recoverable: false,
        };
        state.activeScan = "";
        return;
      }

      scannedItem.scanned = true;
      state.activeScan = "";
    },
    setError: (
      state,
      action: PayloadAction<{ msg: string; recoverable: boolean }>,
    ) => {
      state.itemError = {
        status: true,
        msg: action.payload.msg,
        recoverable: action.payload.recoverable,
      };
    },
    clearError: (state) => {
      if (state.itemError.recoverable) {
        state.itemError = {
          status: false,
          msg: "",
          recoverable: false,
        };
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(reset, () => setInitialState())
      .addMatcher(
        api.endpoints.getOrderById.matchFulfilled,
        (state, action) => {
          const data = action.payload.items;
          /** empty item array, no update */
          if (!data) {
            return;
          }
          state.analogItems = data
            .filter((item) => {
              if (state.department === "Photos") {
                return item.product_name === "Photo";
              }
              return item.product_name === state.department;
            })
            .map((item) => ({ ...item, scanned: false }))
            .sort((a, b) => a.item_barcode.localeCompare(b.item_barcode));

          state.orderData = action.payload;
        },
      );
  },
});

const { actions, reducer } = dataSlice;

export const {
  setOrderId,
  setAccessScan,
  setActiveScan,
  setDepartment,
  resetDepartment,
  scanItem,
  setError,
  clearError,
  clearActiveScan,
} = actions;
export default reducer;

export function scanItemAsync(): AppThunk {
  return async (dispatch, getState) => {
    const { data, auth } = getState();
    const activeScan = data.activeScan;

    /** if they hit enter accidentally, skip */
    if (!activeScan || activeScan.length < 7) {
      dispatch(clearActiveScan());
      return;
    }

    /** if they scan the box instead of item,skip */
    if ([data.accessScan, data.orderIdScan].includes(activeScan)) {
      dispatch(clearActiveScan());
      return;
    }

    /** if they scan a damage sticker, skip */
    if (/NOCONVERT_[0-9]{2}/.test(activeScan)) {
      dispatch(clearActiveScan());
      return;
    }

    const scannedItem = data.analogItems.find(
      (item) => item.item_barcode === activeScan,
    );

    /** Happy path, on to next. */
    if (scannedItem) {
      dispatch(scanItem({ barcode: activeScan }));
      return;
    }

    const validator = isBarcodeMatchingOrderId(
      data.activeScan,
      data.orderIdScan,
    );

    switch (validator) {
      case "waiting":
        return;
      case "valid":
        /** if it's valid but unmatched, probably for another dept? */
        dispatch(clearActiveScan());
        return;
      case "partial-id":
        dispatch(
          setError({
            msg: `Partial ID: ${data.activeScan}`,
            recoverable: true,
          }),
        );
        dispatch(clearActiveScan());
        return;
      case "bad-scan":
        const badscan = `Bad scan: ${data.activeScan}`;
        await createAlert(data.orderIdScan, auth.token, badscan);

        dispatch(
          setError({
            msg: badscan,
            recoverable: false,
          }),
        );
        dispatch(clearActiveScan());
        return;
      case "id-mismatch":
        const idmismatch = `Item doesn't belong in order: ${data.activeScan}`;
        await createAlert(data.orderIdScan, auth.token, idmismatch);

        dispatch(
          setError({
            msg: idmismatch,
            recoverable: false,
          }),
        );
        dispatch(clearActiveScan());
        return;
      default:
        dispatch(setError({ msg: "Unknown error", recoverable: false }));
        dispatch(clearActiveScan());
        return;
    }
  };
}

export const selectOrderId = (state: RootState) => state.data.orderIdScan;
export const selectAccessScan = (state: RootState) => state.data.accessScan;
export const selectActiveScan = (state: RootState) => state.data.activeScan;
export const selectDepartment = (state: RootState) => state.data.department;
export const selectAnalogItems = (state: RootState) => {
  const items = state.data.analogItems;
  if (items.length < 25) {
    return items;
  }
  const unscanned = items.filter(({ scanned }) => !scanned);
  const scanned = items.filter(({ scanned }) => scanned);
  return [...unscanned, ...scanned];
};
export const selectUnscannedItemQty = (state: RootState) =>
  state.data.analogItems.filter(({ scanned }) => !scanned).length;
export const selectErrorState = (state: RootState) => state.data.itemError;
export const selectOrderHeader = (state: RootState) => ({
  orderId: state.data.orderIdScan,
  brand: state.data.orderData?.brand_name ?? "",
  name: state.data.orderData?.shipping_address.lastname ?? "",
  isRush: state.data.orderData?.is_rush ?? false,
});

startAppListening({
  actionCreator: setDepartment,
  effect: (action) => {
    const dept = action.payload;
    localStorage.setItem("SAFEY_CHECK_DEPT", dept);
  },
});

startAppListening({
  actionCreator: resetDepartment,
  effect: () => {
    localStorage.removeItem("SAFEY_CHECK_DEPT");
  },
});
